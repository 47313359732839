import React from "react";
// import { SecurityWhitepaper } from "../graphics/resource/whitepaper";
import { Box, BoxProps } from "../rebrand/core/box";
import { Grid } from "../rebrand/core/Grid/Grid";
import { SmartButton } from "../rebrand/core/SmartButton/SmartButton";
import { Typography } from "../rebrand/core/Typography/Typography";

export interface BlogResourceProps extends BoxProps {
  caption: string;
  title: string;
  cta: {
    label: string;
    path: string;
    external?: boolean;
  };
}

export function BlogResource(props: BlogResourceProps) {
  const { caption, title, cta, ...otherProps } = props;

  return (
    <Box
      backgroundColor="background.faint"
      borderRadius={[3, 4]}
      paddingX={[9, 9, 14]}
      paddingY={[11, 11, 9]}
      {...otherProps}
    >
      <Grid gridRowGap={["3rem", "3rem", "1.5rem"]}>
        <Box
          gridColumn={["1 / span 12", "1 / span 12", "7 / span 6"]}
          gridRow="1"
          display="flex"
          justifyContent="center"
        >
          {/* <SecurityWhitepaper maxWidth={["150px", "250px", "150px"]} /> */}
        </Box>
        <Box
          gridColumn={["1 / span 12", "1 / span 12", "1 / span 5"]}
          gridRow={["2", "2", "1"]}
          textAlign={["left", "center", "left"]}
        >
          <Typography variant="label2">{caption}</Typography>
          <Box marginTop={4}>
            <Typography variant="h3">{title}</Typography>
          </Box>
          <Box display="inline-flex" marginTop={6}>
            <SmartButton path={cta.path} external={cta.external}>
              {cta.label}
            </SmartButton>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}
