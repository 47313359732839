import React from "react";
import { graphql, PageProps } from "gatsby";

import { BlogCardsGrid } from "../components/blog/cards-grid";
import { BlogLayout } from "../components/blog/layout";
import { BlogResource } from "../components/blog/resource"

import { Typography } from "../components/rebrand/core/Typography/Typography";
import { PageHeaderSection } from "../components/rebrand/sections/PageHeader/PageHeaderSection";
import { Section } from "../components/rebrand/sections/Section/Section";
import { SEO } from "../components/seo";
import { BlogIndexPageData } from "../data/blog";

export const pageQuery = graphql`
  {
    featuredPosts: allMdx(
      filter: {frontmatter: {hidden: {eq: false}, featured: {eq: true}}}
      sort: {fields: frontmatter___publishDate, order: DESC}
      limit: 1
    ) {
      nodes {
        id
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        frontmatter {
          slug
          title
          subtitle
          author
          publishDate
          pinned
          featured
        }
      }
    }
    site {
      siteMetadata {
        pages {
          blog {
            title
            description
            keywords
          }
        }
      }
    }
    posts: allMdx(
      filter: {frontmatter: {hidden: {eq: false}}}
      sort: {fields: frontmatter___publishDate, order: DESC}
    ) {
      nodes {
        id
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        frontmatter {
          slug
          title
          subtitle
          author
          publishDate
          pinned
          featured
        }
      }
    }
  }
`;


interface BlogIndexPageProps extends PageProps {
  data: {
    featuredPosts: {
      nodes: {
        id: string;
        featuredImage: Queries.MdxFrontmatter;
        frontmatter: Queries.MdxFrontmatter;
      }[];
    };
    posts: {
      nodes: {
        featuredImage: Queries.MdxFrontmatter;
        id: string;
        frontmatter: Queries.MdxFrontmatter;
      }[];
    };
    site: {
      siteMetadata: {
        pages: {
          blog: Queries.SiteSiteMetadataPagesBlog;
        };
      };
    };
  };
}

export default function BlogIndexPage(props: BlogIndexPageProps) {
  const { location, data } = props;
  const { title, description, keywords } = data.site.siteMetadata.pages.blog;
  const { pathname } = props.location;

  const featuredPosts = data.featuredPosts.nodes.map((node) => ({
    ... node.frontmatter,
    featuredImage : node.featuredImage
  }));
  const featuredPostsIDs = data.featuredPosts.nodes.map((node) => node.id);

  const highlightedPostsIDs = [...featuredPostsIDs];

  const nonHighlightedPosts = data.posts.nodes
    .filter((node) => !highlightedPostsIDs.includes(node.id))
    .map((node) => ({
      ... node.frontmatter,
      featuredImage : node.featuredImage
    }));

  return (
    <BlogLayout currentPath={location.pathname}>
      <SEO title={title} description={description} keywords={[...keywords]} pathname={pathname} />
      <PageHeaderSection
        paddingTopSpacing={[5, 9]}
        paddingBottomSpacing={[5.25, 8.5]}
        {...BlogIndexPageData.pageTitle}
      />
      <Section paddingTopSpacing={[0, 0]} paddingBottomSpacing={[0, 0]} disableGutters={false}>
        <BlogCardsGrid variant="wide" items={featuredPosts} />
        <BlogResource marginBottomSpacing={[3, 6.25]} marginX={[0, 0, -12]} {...BlogIndexPageData.featuredResource} />
        <Typography variant="h3" marginBottomSpacing={[3, 3, 3.5]}>
          {BlogIndexPageData.allPosts.title}
        </Typography>
        <BlogCardsGrid items={nonHighlightedPosts} />
      </Section>
    </BlogLayout>
  );
}
